.header {
  background: linear-gradient(
    254.97deg,
    #e7e8ff 4.15%,
    #b2b5ff 28.82%,
    #5f28b9 55.73%,
    #0c51a3 78.45%
  );
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 0;
  min-height: 80px;
  display: flex;
  align-items: center;
  z-index: 99;
  @media (max-width: 575.98px) {
    background: #0c51a3 78.45%;
  }
}

.navbar-brand {
  color: #fff !important;
  font-family: "Merriweather", serif;
  @media (max-width: 992.98px) {
    display: block;
    overflow: hidden;
    width: 40px;
    margin-right: 5px;
  }
}
.navbar-toggler {
  border: 0;
  padding: 0;
  box-shadow: none;
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  span.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg fill='none' height='15' viewBox='0 0 15 15' width='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z' fill='%23ffffff' fill-rule='evenodd'/%3E%3C/svg%3E");
  }
}
@media (max-width: 991.98px) {
  div#basic-navbar-nav {
    position: absolute;
    top: 100%;
    left: 42px;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.15);
    min-width: 230px;
    .navbar-nav {
      a {
        color: #282828;
      }
    }
  }
  //   .navbar-collapse.show{
  //   position: absolute;
  //     top: 100%;
  //     left: 42px;
  //     background: #fff;
  //     padding: 10px;
  //     border-radius: 10px;
  //     box-shadow: 0 0 9px rgba(0, 0, 0, 0.15);
  //     min-width: 230px;
  //     .navbar-nav{
  //       a{
  //         color: #282828;
  //       }
  //     }
  // }
}

.navbar-brand img {
  max-width: 155px;
}
.nav-wrap {
  display: flex;
  align-items: center;
  @media (max-width: 575.98px) {
    justify-content: space-between;
    z-index: 9;
  }
}

.navbar-nav a {
  color: #bccad3;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.navbar-nav a:hover {
  color: #ddd;
}

a.log-btn.nav-link,
a.reg-r-btn.nav-link {
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 2px 0px rgba(19, 90, 195, 0.4));
  border-radius: 22px;
  color: #fff;
  background: #15bb58;
  min-height: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 500;
  margin-left: 25px;
  font-family: "Lato", sans-serif;
  border: 1px solid #4ce689;
  box-shadow: 0px 2px 0px rgba(19, 90, 195, 0.4);
  @media screen and (max-width: 1199.98px) {
    margin-left: 8px;
    padding: 0 12px;
  }
}

.nav-login {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Lato", sans-serif;
  @media screen and (max-width: 575.98px) {
    padding-bottom: 12px;
    justify-content: space-between;
    padding-right: 0;
    padding-left: 0;
  }
}

a.reg-r-btn.nav-link {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ffffff;
  box-shadow: 0px 2px 0px rgba(19, 90, 195, 40%);
  border-radius: 22px;
  color: #135ac3;
}

a.reg-r-btn.nav-link:hover,
a.log-btn.nav-link:hover {
  background: #5f28b9;
  color: #fff;
}

a.log-btn-txt.nav-link {
  color: #135ac3;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-left: 22px;
  @media screen and (max-width: 767.98px) {
    margin-left: 12px;
  }
  @media screen and (max-width: 575.98px) {
    color: #fff;
  }
}

section.top-search {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  // box-shadow: 0px 2px 10px rgba(0, 0, 0, 16%);
  margin-top: 78px;
  @media screen and (max-width: 767.98px) {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  @media screen and (max-width: 575.98px) {
    padding-top: 40px;
  }
}

section.top-search .inner_area {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (max-width: 575.98px) {
    .css-175dbwz-control {
      width: 100%;
    }
  }
  @media screen and (max-width: 575.98px) {
    justify-content: space-between;
  }
}

section.top-search .inner_area .col-in {
  display: flex;
  flex-direction: column;
  padding-right: 34px;
  padding-top: 19px;
  padding-bottom: 15px;
  @media screen and (max-width: 1199.98px) {
    padding-right: 12px;
  }
  @media screen and (max-width: 767.98px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @media screen and (max-width: 575.98px) {
    width: 48.333%;
  }
}

section.top-search .inner_area .col-in label {
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 4px;
}

select {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  min-height: 44px;
  font-size: 16px;
  color: #282828;
  padding: 0 21px;
  min-width: 180px;
}

input[type="submit"] {
  background: none;
  color: #e8505b;
  border: 0;
  font-weight: 600;
  font-size: 16px;
}

section.top-search .inner_area .col-in:last-child {
  padding-right: 0;
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 1199.98px) {
    padding-left: 5px;
  }
}

section.top-search .inner_area .col-in:last-child svg {
  position: relative;
  top: 1px;
}

.footer {
  background: linear-gradient(
    254.97deg,
    #e7e8ff 4.15%,
    #b2b5ff 28.82%,
    #5f28b9 55.73%,
    #0c51a3 78.45%
  );
  .blits-addr-cnt {
    margin-top: 20px;
    a {
      text-decoration: none;
      margin: 0 15px;
      display: inline-block;
      color: #d6d5d5;
      font-size: 16px;
    }
  }
  .container-fluid.sk {
    background: #7c5cde;
  }
  .privacy_link {
    color: #fff;
    padding: 10px 0;
  }
}

.soc-in {
  padding: 20px 10px;
}

.p-copy {
  background: linear-gradient(
    254.97deg,
    #e7e8ff 4.15%,
    #6064cc 28.82%,
    #682bcb 55.73%,
    #0c51a3 78.45%
  );
  text-align: center;
  color: #fff;
  padding: 17px 0;
  font-size: 14px;
}

.blits_nav .dropdown-menu {
  border-radius: 20px;
  padding: 15px 0;
}

.blits_nav .dropdown-menu a {
  color: #444444;
  font-size: 16px;
  padding: 8px 20px;
}

.blits_nav .dropdown-menu a:hover {
  color: black;
}

.profile_nav a {
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 2px;
  text-transform: capitalize;

  color: #703fc1;
  @media screen and (max-width: 767.98px) {
    color: #fff;
  }
}

.purpleText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #703fc1;
}

.list_property_nav {
  padding-left: 1.5rem;
  color: #bccad3;
  font-size: 18px;
  @media screen and (max-width: 767.98px) {
    padding-left: 0.2rem;

    font-size: 15px;
  }
  &:hover {
    color: #fff;
  }

  &:focus {
    color: #fff;
  }
}

.error_banner {
  display: flex;
  background-color: #ff725e;
  // width: fit-content;
  padding: 15px 20px;
  border-radius: 8px;
  margin-bottom: 1rem;
}
.error_banner p {
  color: white;
}
.error_banner button {
  background: white;
  border: none;
  border-radius: 4px;
  margin-left: auto;
}
.privacy_link {
  text-align: right;
  @media (max-width: 767.98px) {
    text-align: center;
  }
  a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
  }
}
.terms_page {
  padding-top: 120px;
  padding-bottom: 120px;
  h3 strong {
    font-size: 16px;
  }
  td {
    vertical-align: middle;
    width: auto !important;
    padding: 8px;
    border: 1px solid #ddd;
  }

  td p {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }
  p {
    font-weight: normal;
  }
  h2 {
    display: block;
    margin-top: 15px;
  }
  table {
    width: 100%;
  }
  h4 strong {
    font-size: 16px;
  }
}

.scroll-to-top.def-btn {
  min-height: unset;
  position: fixed;
  bottom: 20%;
  right: 2%;
  width: 45px;
  height: 45px;
  float: none;
  border-radius: 100px;
  border: 0;
  padding: 0;
  z-index: 9;
}
.scroll-to-top.def-btn span {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  vertical-align: middle;
  margin-top: 6px;
}
.footer-newsletter-form {
  padding-top: 15px;
  padding-bottom: 15px;
  @media (max-width: 568px) {
    padding-top: 0;
  }
  .footer-newsletter-title {
    display: block;
    margin-bottom: 7px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    @media (max-width: 568px) {
      text-align: center;
    }
  }
  .form-control {
    min-width: 280px;
    width: 100%;
    @media (max-width: 568px) {
      min-width: unset;
    }
  }
  .error-msg {
    position: absolute;
    @media (max-width: 568px) {
      position: static;
    }
  }
  .def-btn {
    float: none;
    min-height: 40px;
    font-size: 14px;
    white-space: nowrap;
    @media (max-width: 568px) {
      width: 100%;
      margin-top: 15px !important;
    }
  }
}
.download-brochure-link {
  font-size: 17px;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
}
.offer-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  &.open {
    visibility: visible;
    opacity: 1;
  }
  .offer-popup-inner {
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    width: 60%;
    position: relative;
    padding: 30px;
    @media (max-width: 568px) {
      width: 90%;
    }
    .offer-popup-img {
      img {
        max-width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 568px) {
          max-width: 80%;
        }
      }
    }
    .offer-popup-con {
      @media (max-width: 568px) {
        text-align: center;
      }
      span {
        color: #23244b;
        font-size: 24px;
        font-weight: 600;
        display: block;
        margin-bottom: 10px;
        @media (max-width: 568px) {
          font-size: 18px;
        }
      }
      h1 {
        font-size: 40px;
        @media (max-width: 568px) {
          font-size: 28px;
        }
        color: #1a2163;
        font-family: "Merriweather", serif;
        font-weight: 700;
        b {
          color: #15bb58;
        }
      }
      p {
        font-size: 20px;
        font-weight: 400;
        color: #23244b;
        display: block;
        line-height: 1.4;
        margin-bottom: 30px;
        @media (max-width: 568px) {
          font-size: 16px;
          margin-bottom: 15px;
        }
      }
    }
    .tc-apply-text {
      position: absolute;
      bottom: 15px;
      right: 25px;
      font-size: 14px;
      color: #666666;
    }
    .closePopup {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 30px;
      height: 30px;
      transition: all 0.4s ease-in-out;
      z-index: 2;
      cursor: pointer;
    }
    .closePopup:before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 77%;
      height: 2px;
      background: #444444;
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      transition: 600ms all;
      -webkit-transition: 600ms all;
    }
    .closePopup:after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 77%;
      height: 2px;
      background: #444444;
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transition: 600ms all;
      -webkit-transition: 600ms all;
    }
  }
}
